<script setup>
  const content = `隱私權政策宣告的適用範圍：
以下的隱私權宣告，適用於您在使用「苗栗縣地方產業發展策略資訊網」網站服務時，所涉及的個人資料蒐集、運用與保護。

壹、個人資料之蒐集及使用方式
單純在「苗栗縣地方產業發展策略資訊網」網站的瀏覽及檔案下載行為，本網站並不會蒐集任何有關個人的身份資料。

「苗栗縣地方產業發展策略資訊網」有義務保護各申請人隱私，非經您本人同意不會自行修改或刪除任何個人資料及檔案。除非經過您事先同意或符合以下情況始得為之：
１．經由合法的途徑。
２．保護或防衛相關個人的權利或所有權。

貳、資料分享及公開方式
「苗栗縣地方產業發展策略資訊網」絕不會任意出售、交換、或出租任何您的個人資料給其他團體、個人或私人企業。但有下列情形者除外：
１．配合司法單位合法的調查。
２．配合相關職權機關依職務需要之調查或使用。
３．基於善意相信揭露為法律需要，或為維護和改進網站服務而用於管理。

叁、隱私權政策宣告之諮詢
如果您對「苗栗縣地方產業發展策略資訊網」的隱私權政策宣告有任何疑問，歡迎來電(037-558724)與我們聯絡。`
</script>

<template>
  <div class="container main-width license-container">
    <pre>{{ content }}</pre>
  </div>
</template>

<style lang="scss" scoped>
  pre {
    white-space: pre-wrap;
  }
</style>
